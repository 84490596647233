import { Box, Typography } from '@material-ui/core';

const SlashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000,
    }}
  >
    <Typography variant="h5" color="textPrimary" gutterBottom>
      Kan ikke koble til server
    </Typography>
    <Typography variant="body" color="textPrimary">
      Oops, noe gikk galt. Vennligs sjekk din internet tilkobling.
    </Typography>
  </Box>
);

export default SlashScreen;
