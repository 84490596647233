import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import CookieGuard from './components/CookieGuard';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import TermsGuard from './components/TermsGuard';
import OnlineGuard from './components/OnlineGuard';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const VippsLogin = Loadable(lazy(() => import('./pages/authentication/VippsLogin')));

// Consent pages

const ConsentIntro = Loadable(lazy(() => import('./pages/consent/ConsentIntro')));
const Consent = Loadable(lazy(() => import('./pages/consent/Consent')));

// Docs pages

const LegalTermsApp = Loadable(lazy(() => import('./pages/TermsOfUseApp')));
const LegalPrivacyApp = Loadable(lazy(() => import('./pages/PrivacyApp')));
const LegalTermsProject = Loadable(lazy(() => import('./pages/TermsOfUseProject')));
const LegalPrivacyProject = Loadable(lazy(() => import('./pages/PrivacyProject')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Other pages

const Home = Loadable(lazy(() => import('./pages/Home')));
const TermsAccept = Loadable(lazy(() => import('./pages/Terms')));
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const CookieInfo = Loadable(lazy(() => import('./pages/CookieInfo')));

const routes = [
  {
    path: 'authentication',
    element: (
      <OnlineGuard>
        <GuestGuard>
          <CookieGuard>
            <MainLayout />
          </CookieGuard>
        </GuestGuard>
      </OnlineGuard>
    ),
    children: [
      {
        path: 'login',
        element: <VippsLogin />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <OnlineGuard>
        <GuestGuard>
          <CookieGuard>
            <MainLayout />
          </CookieGuard>
        </GuestGuard>
      </OnlineGuard>
    ),
    children: [
      {
        path: '/',
        element: <Home />,
      },
    ],
  },
  {
    path: '/account',
    element: (
      <OnlineGuard>
        <AuthGuard>
          <CookieGuard>
            <MainLayout />
          </CookieGuard>
        </AuthGuard>
      </OnlineGuard>
    ),
    children: [
      {
        path: '/',
        element: <Account />,
      },
    ],
  },
  {
    path: 'consent',
    element: (
      <OnlineGuard>
        <AuthGuard>
          <TermsGuard>
            <CookieGuard>
              <MainLayout />
            </CookieGuard>
          </TermsGuard>
        </AuthGuard>
      </OnlineGuard>
    ),
    children: [
      {
        path: '/',
        element: <ConsentIntro />,
      },
      {
        path: 'details',
        element: <Consent />,
      },
    ],
  },
  {
    path: 'terms',
    element: (
      <OnlineGuard>
        <AuthGuard>
          <CookieGuard>
            <MainLayout />
          </CookieGuard>
        </AuthGuard>
      </OnlineGuard>
    ),
    children: [
      {
        path: '/',
        element: <TermsAccept />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <CookieGuard>
        <MainLayout />
      </CookieGuard>
    ),
    children: [
      {
        path: 'legal/terms',
        element: <LegalTermsApp />,
      },
      {
        path: 'legal/privacy',
        element: <LegalPrivacyApp />,
      },
    ],
  },
  {
    path: 'projectlegal',
    element: (
      <OnlineGuard>
        <AuthGuard>
          <TermsGuard>
            <CookieGuard>
              <MainLayout />
            </CookieGuard>
          </TermsGuard>
        </AuthGuard>
      </OnlineGuard>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/projectlegal/terms" />,
      },
      {
        path: '/terms',
        element: <LegalTermsProject />,
      },
      {
        path: '/privacy',
        element: <LegalPrivacyProject />,
      },
      {
        path: '/projectdetails',
        element: <LegalPrivacyProject />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <OnlineGuard>
        <CookieGuard>
          <MainLayout />
        </CookieGuard>
      </OnlineGuard>
    ),
    children: [
      {
        path: 'cookie',
        element: <CookieInfo />,
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
