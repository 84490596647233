import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../data/baseUrl';

const initialState = {
  userTerms: {
    hasAcceptedTerms: null,
    userId: '',
  },
  loadingUserTerms: true,
  error: null,
};

const slice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    requestUserTermsAccepted(state, action) {
      state.loadingUserTerms = false;
      state.error = null;
      state.userTerms = action.payload;
    },
    updateUserTermsAccepted(state, action) {
      state.error = null;
      state.userTerms = action.payload;
    },
    termsAndConditions(state, action) {
      state.error = null;
      state.termsAndConditions = action.payload;
    },
  },
});

export const { reducer } = slice;

export const requestUserTermsAccepted = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}/me/checkUserTerms`);

  dispatch(slice.actions.requestUserTermsAccepted(res.data));
};

export const requestTermsAndConditions = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}/userTermsAndConditions`);

  dispatch(slice.actions.termsAndConditions(res.data));
};

export const updateUserTermsAccepted = (termsAndConditionsId, update, cb) => async (dispatch) => {
  const res = await axios.put(`${baseUrl}/me/updateUserTerms/${termsAndConditionsId}`, update);

  await requestUserTermsAccepted()(dispatch);
  await requestTermsAndConditions()(dispatch);

  cb();

  dispatch(slice.actions.updateUserTermsAccepted(res.data));
};

export default slice;
