import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../store';
import SplashScreen from './SplashScreen';
import { requestUserTermsAccepted, requestTermsAndConditions } from '../slices/terms';

function TermsGuard({ children }) {
  const dispatch = useDispatch();
  const { userTerms, termsAndConditions } = useSelector((state) => state.terms);

  useEffect(() => {
    dispatch(requestUserTermsAccepted());
    dispatch(requestTermsAndConditions());
  }, [dispatch]);

  if (!userTerms || !termsAndConditions?.id) {
    return <SplashScreen />;
  }

  if (userTerms && userTerms.hasAcceptedTerms === false) {
    return <Navigate to="/terms" />;
  }

  return children;
}

TermsGuard.propTypes = {
  children: PropTypes.node,
};

export default TermsGuard;
