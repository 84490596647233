import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import AxiosError from 'axios-error';
import Timeout from 'await-timeout';
import objFromArrayWithNestedObject from '../utils/objFromArrayWithNestedObject';
import baseUrl from '../data/baseUrl';

const consentStates = { draft: 'DRAFT', active: 'ACTIVE', rejected: 'REJECTED', revoked: 'REVOKED' };
const returningConsentStates = [consentStates.active, consentStates.revoked, consentStates.rejected];

const initialState = {
  activeConsentId: null,
  activeConsentState: null,
  activeConsent: null,
  isReturningUser: false,
  loadingConsent: true,
  error: null,
  consents: {
    byId: {},
    allIds: [],
  },
};

const slice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    getConsent(state, action) {
      state.loadingConsent = false;
      state.error = null;
      state.activeConsent = action.payload[0];
      state.activeConsentId = state.activeConsent.id;
      state.activeConsentState = state.activeConsent.state;
      state.isConsentRevoked = state.activeConsentState.toUpperCase() === consentStates.revoked;
      state.isConsentRejected = state.activeConsentState.toUpperCase() === consentStates.rejected;
      state.isDraft = state.activeConsentState.toUpperCase() === consentStates.draft;
      state.isReturningUser = returningConsentStates.includes(state.activeConsentState.toUpperCase());
      state.consents.byId = objFromArrayWithNestedObject(state.activeConsent.consentOptions);
      state.consents.allIds = Object.keys(state.consents.byId);
    },
    getConsentError(state, action) {
      state.loadingConsent = false;
      state.error = action.payload;
    },
    updateConsent(state) {
      state.error = null;
    },
    updateConsentError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getConsent = (projectId) => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/me/projects/${projectId}/consents`);

    dispatch(slice.actions.getConsent(res.data));
  } catch (error) {
    const axiosError = new AxiosError(error);

    if (axiosError.response.status === 401) {
      window.location = '/login';
    }
    dispatch(slice.actions.getConsentError(axiosError));
  }
};

export const updateConsent = (projectId, consentId, update, isReturningUser) => async (dispatch) => {
  try {
    await axios.put(`${baseUrl}/me/projects/${projectId}/consents/${consentId}`, update);

    await Timeout.set(500);

    window.location.reload();
  } catch (error) {
    const axiosError = new AxiosError(error);

    if (axiosError.response.status === 401) {
      window.location = '/login';
    }
    dispatch(slice.actions.updateConsentError(axiosError));
  }
};

export default slice;
