import { Box, Button, Dialog, makeStyles, Paper, Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#0e214b',
  },
  text: {
    color: '#ffffff',
  },
  content: {
    position: 'fixed',
    bottom: '0',
    margin: '0',
    width: '100%',
  },
}));

const cookieStorage = {
  getItem: (key) => {
    const cookies = document.cookie
      .split(';')
      .map((cookie) => cookie.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
    return cookies[key];
  },
  setItem: (key, value) => {
    document.cookie = `${key}=${value};path=/;Secure;max-age=31536000`;
  },
};

const storageType = cookieStorage;
const cookieConsentPropertyName = 'samtykke_consent';

const shouldShowPopup = () => !storageType.getItem(cookieConsentPropertyName);
const saveToStorage = () => storageType.setItem(cookieConsentPropertyName, true);

function DialogContent(props) {
  const classes = useStyles();

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
      }}
    >
      <Paper className={classes.root} elevation={12} sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.text} align="center" color="textSecondary" sx={{ mt: 1 }} variant="body2">
            Velkommen! Vi bruker nødvendige cookies for trafikkmåling og optimalisering av tjenesten. Fortsett å bruke
            tjenesten hvis du godtar dette.
          </Typography>
          <Link component={RouterLink} to="/Cookie" underline="always">
            Les mer om informasjonskapsel her
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            mt: 4,
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button color="primary" fullWidth variant="contained" onClick={props.onConfirm} sx={{ maxWidth: 450 }}>
            GODTA
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

function CookieGuard({ children }) {
  const classes = useStyles();
  const [CookieConsentModal, setCookieConsentModal] = useState({
    isVisible: false,
  });

  useEffect(() => {
    if (shouldShowPopup()) {
      setCookieConsentModal({ isVisible: true });
    }
  }, []);

  const cookieConsentConfirm = () => {
    saveToStorage();
    setCookieConsentModal({ isVisible: false });
  };

  return (
    <div>
      <Dialog
        sx={{ width: '0' }}
        disableEscapeKeyDown="true"
        hideBackdrop="true"
        maxWidth="lg"
        open={CookieConsentModal.isVisible}
        onClose={() => setCookieConsentModal({ isVisible: false })}
      >
        <div className={classes.content}>
          <DialogContent onConfirm={cookieConsentConfirm} />
        </div>
      </Dialog>
      {children}
    </div>
  );
}

export default CookieGuard;
