import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import AxiosError from 'axios-error';
import baseUrl from '../data/baseUrl';

const initialState = {
  authId: null,
  loadingSelf: true,
  loadingAdministratorsNotification: false,
  self: {},
  error: null,
};

const slice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    requestUserSelf(state, action) {
      const [identity] = action.payload.identities;

      state.loadingSelf = false;
      state.error = null;
      state.self = action.payload;
      state.userName = `${action.payload?.firstName} ${action.payload?.lastName}`;
      state.identity = identity;
      state.user = identity;
      state.userPhone = `+${identity?.phone?.countryCode}${identity?.phone?.number}`;
      state.userContact = identity.phone ? `+${identity.phone.countryCode}${identity.phone.number}` : identity.email;
      state.authId = state.self.id;
    },
    requestUserSelfError(state, action) {
      state.loadingSelf = false;
      state.error = action.payload;
    },
    requestNotificationRequest(state) {
      state.loadingAdministratorsNotification = true;
      state.error = null;
    },
    requestNotification(state, action) {
      state.loadingAdministratorsNotification = false;
      state.error = action.payload;
    },
    requestProjectNotificationRequest(state) {
      state.loadingProjectNotification = true;
      state.error = null;
    },
    requestProjectNotification(state, action) {
      state.loadingProjectNotification = false;
      state.error = action.payload;
    },
  },
});

export const { reducer } = slice;

export const requestUserSelf =
  (options = {}) =>
  async (dispatch) => {
    try {
      const res = await axios.get(`${baseUrl}/me`);

      dispatch(slice.actions.requestUserSelf(res.data));
    } catch (error) {
      const axiosError = new AxiosError(error);

      if (axiosError.response.status === 401 && !options.isLandingPage) {
        window.location = '/login';
      }
      dispatch(slice.actions.requestUserSelfError(axiosError));
    }
  };

export const requestAdministratorsNotification =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.requestNotificationRequest());

      const res = await axios.post(`${baseUrl}/me/app-notifications/${options.type}`);

      dispatch(slice.actions.requestNotification(res.data));
    } catch (error) {
      const axiosError = new AxiosError(error);

      if (axiosError.response.status === 401 && !options.isLandingPage) {
        window.location = '/login';
      }
      dispatch(slice.actions.requestNotification(axiosError));
    }
  };

export const requestProjectNotification =
  (options = {}, projectId) =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.requestProjectNotificationRequest());

      const res = await axios.post(`${baseUrl}/me/project-notifications/project/${projectId}/${options.type}`);

      dispatch(slice.actions.requestProjectNotification(res.data));
    } catch (error) {
      const axiosError = new AxiosError(error);

      if (axiosError.response.status === 401 && !options.isLandingPage) {
        window.location = '/login';
      }
      dispatch(slice.actions.requestProjectNotification(axiosError));
    }
  };

export default slice;
