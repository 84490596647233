import { useState, useEffect } from 'react';
import OfflineScreen from './OfflineScreen';

const inBrowser = typeof navigator !== 'undefined';

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const ping = ({ url, timeout }) =>
  new Promise((resolve) => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);

    const xhr = new XMLHttpRequest();

    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };

    xhr.open('GET', url);
    xhr.timeout = timeout;
    xhr.send();
  });

const defaultPollingConfig = {
  enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
  url: '/health',
  timeout: 5000,
  interval: 5000,
};

function OnlineGuard({ children }) {
  const [online, setOnline] = useState(inBrowser && typeof navigator.onLine === 'boolean' ? navigator.onLine : true);
  const [pollingId, setPollingId] = useState(null);

  const goOnline = () => {
    if (!online) {
      setOnline(true);
    }
  };

  const goOffline = () => {
    if (online) {
      setOnline(false);
    }
  };

  const startPolling = () => {
    const { interval } = defaultPollingConfig;
    const pollingId = setInterval(() => {
      const { url, timeout } = defaultPollingConfig;
      ping({ url, timeout }).then((result) => {
        setOnline(result);
      });
    }, interval);

    setPollingId(pollingId);
  };

  const stopPolling = () => {
    clearInterval(pollingId);
  };

  useEffect(() => {
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    if (defaultPollingConfig.enabled) {
      startPolling();
    }

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);

      if (pollingId) {
        stopPolling();
      }
    };
  }, []);

  if (!online) {
    return <OfflineScreen />;
  }

  return children;
}

export default OnlineGuard;
