import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from '../store';
import { requestUserSelf } from '../slices/me';
import LoadingWrapper from './LoadingWrapper';

const GuestGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { loadingSelf, authId } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(requestUserSelf({ isLandingPage: true }));
  }, [dispatch]);

  if (authId && authId.length) {
    return <Navigate to="/consent/details" />;
  }

  return <LoadingWrapper isLoading={loadingSelf}>{children}</LoadingWrapper>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
