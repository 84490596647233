import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../store';
import { requestUserSelf } from '../slices/me';
import SplashScreen from './SplashScreen';
import { bootstrapApp } from '../slices/project';
import mockedProject from '../data/actualProject';

function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { self, authId, error } = useSelector((state) => state.me);

  useEffect(() => {
    (async () => {
      await dispatch(requestUserSelf());
      dispatch(bootstrapApp(mockedProject.id));
    })();
  }, [dispatch]);

  if (error && error.response && error.response.status === 401) {
    navigate('/401');
  }

  if (!authId || !authId.length || !self || !self.id) {
    return <SplashScreen />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
