import React from 'react';
import PropTypes from 'prop-types';
import LoadingScreen from './LoadingScreen';
import LoadingCircularScreen from './LoadingCircularScreen';

function LoadingWrapper({ children, isLoading, circular = false }) {
  if (!isLoading) {
    return children;
  }

  return circular ? <LoadingCircularScreen /> : <LoadingScreen />;
}

LoadingWrapper.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  circular: PropTypes.bool,
};

export default LoadingWrapper;
