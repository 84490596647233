import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Container,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import useLogo from 'hooks/useLogo';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import LockOutlined from '@material-ui/icons/LockOutlined';
import TocOutlined from '@material-ui/icons/TocOutlined';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SunIcon from '../icons/Sun';
import MoonIcon from '../icons/Moon';
import useSettings from '../hooks/useSettings';
import { THEMES } from '../constants';
import getInitials from '../utils/getInitials';
import { useSelector } from '../store';

const useStyles = makeStyles(({ spacing, palette }) => ({
  avatar: {
    height: 32,
    width: 32,
    fontSize: spacing(1.75),
    marginRight: spacing(1),
    backgroundColor: palette.landGreen.main,
  },
  logo: {
    height: 40,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 64,
  },
  subheader: {
    lineHeight: 'inherit',
    marginBottom: spacing(2),
    marginTop: spacing(2),
  },
  popover: {
    width: 250,
  },
  hidden: { visibility: 'hidden' },
}));

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
});

const MainNavbar = () => {
  const classes = useStyles();
  const { userName, authId, userContact } = useSelector((state) => state.me);
  const [isOpen, setOpen] = useState(false);
  const [hasSettingsOpen, setSettingsOpen] = useState(false);
  const ref = useRef(null);
  const Logo = useLogo(classes);
  const { settings, saveSettings } = useSettings();
  const [selectedTheme, setSelectedTheme] = useState(settings.theme);

  const handleSwitch = (theme) => {
    if (theme) {
      saveSettings({ ...getValues(settings), theme });

      return theme;
    }

    setSelectedTheme((prevSelectedTheme) => {
      if (prevSelectedTheme === THEMES.LIGHT) {
        if (settings.theme === THEMES.LIGHT) {
          saveSettings({ ...getValues(settings), theme: THEMES.DARK });
          return THEMES.DARK;
        }

        saveSettings({ ...getValues(settings), theme: settings.theme });
        return settings.theme;
      }

      saveSettings({ ...getValues(settings), theme: THEMES.LIGHT });
      return THEMES.LIGHT;
    });
  };

  const handleOpen = () => {
    setSettingsOpen(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSettingsOpen = () => {
    setOpen(false);
    setSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  const handleLogout = () => {
    handleClose();
    window.location = '/logout';
  };

  const handleKontaktOss = (e) => {
    window.location = 'mailto:samtykkeformidleren@dnv.com';
    e.preventDefault();
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary',
      }}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">{Logo}</RouterLink>
        <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!authId ? (
            <IconButton onClick={() => handleSwitch()}>
              {selectedTheme === 'LIGHT' ? <MoonIcon fontSize="small" /> : <SunIcon fontSize="small" />}
            </IconButton>
          ) : (
            <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
              <Avatar className={classes.avatar}>{getInitials(userName)}</Avatar>
              <Typography variant="h6" color="inherit" sx={{ display: { xs: 'none', md: 'block' } }}>
                {userName}
              </Typography>
            </Box>
          )}
          <Menu
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}
            MenuListProps={{
              subheader: (
                <ListSubheader component="div" className={classes.subheader}>
                  <Box>
                    <Typography variant="h5" color="textPrimary">
                      {userName}
                    </Typography>
                    <Box>{`(${userContact})`}</Box>
                    <Box mt={1}>
                      <Link component={RouterLink} to="/account" variant="subtitle1">
                        Innstillinger
                      </Link>
                    </Box>
                  </Box>
                </ListSubheader>
              ),
            }}
          >
            <Divider />
            <MenuItem onClick={handleSettingsOpen} sx={{ mt: 1 }}>
              <ListItemIcon>
                <Brightness4Icon />
              </ListItemIcon>
              <ListItemText primary="Utseende" />
            </MenuItem>
            <MenuItem component={Link} href="https://dnv.no" target="_blank" onClick={handleClose}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="Om DNV" />
            </MenuItem>
            <MenuItem component={Link} href="/legal/privacy">
              <ListItemIcon>
                <LockOutlined />
              </ListItemIcon>
              <ListItemText primary="Personvernerklæring" />
            </MenuItem>
            <MenuItem component={Link} href="/legal/terms">
              <ListItemIcon>
                <TocOutlined />
              </ListItemIcon>
              <ListItemText primary="Brukervilkårene" />
            </MenuItem>
            <MenuItem onClick={handleKontaktOss} sx={{ mb: 1 }}>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary="Kontakt oss" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout} sx={{ mt: 1 }}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logg ut" />
            </MenuItem>
          </Menu>
          <Menu
            open={hasSettingsOpen}
            onClose={handleSettingsClose}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            PaperProps={{ className: classes.popover }}
            MenuListProps={{
              subheader: (
                <ListSubheader component="div" className={classes.subheader}>
                  <Box display="flex" alignItems="center" my={1}>
                    <IconButton onClick={handleOpen}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h5" color="textPrimary">
                      Utseende
                    </Typography>
                  </Box>
                </ListSubheader>
              ),
            }}
          >
            <Divider />
            <MenuItem
              onClick={() => {
                handleSettingsClose();
                handleSwitch(THEMES.LIGHT);
              }}
            >
              <ListItemIcon>
                <CheckIcon className={clsx(settings.theme !== THEMES.LIGHT && classes.hidden)} />
              </ListItemIcon>
              <ListItemText primary="Light theme" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSettingsClose();
                handleSwitch(THEMES.DARK);
              }}
            >
              <ListItemIcon>
                <CheckIcon className={clsx(settings.theme !== 'DARK' && classes.hidden)} />
              </ListItemIcon>
              <ListItemText primary="Dark theme" />
            </MenuItem>
          </Menu>
        </Container>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default MainNavbar;
