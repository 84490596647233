import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import AxiosError from 'axios-error';
import objFromArray from '../utils/objFromArray';
import baseUrl from '../data/baseUrl';
import { getConsent } from './consent';
import mockedProject from '../data/actualProject';

const initialState = {
  activeProjectId: null,
  activeProjectName: null,
  projectRules: null,
  hasProjectJoined: false,
  joinedProject: null,
  loadingProjects: true,
  error: null,
  projects: {
    byId: {},
    allIds: [],
  },
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    getUserProjects(state, action) {
      state.loadingProjects = false;
      state.error = null;
      const projects = action.payload;
      state.projectRules = projects[0].consentTemplates[0].rules;
      state.activeProjectId = projects[0].id;
      state.activeProjectName = projects[0].name;

      state.projects.byId = objFromArray(projects);
      state.projects.allIds = Object.keys(state.projects.byId);
    },
    getUserProjectsError(state, action) {
      state.loadingProjects = false;
      state.error = action.payload;
    },
    getJoinProject(state, action) {
      state.error = null;
      state.joinedProject = action.payload;
    },
    getJoinProjectError(state, action) {
      state.error = action.payload;
    },
    getHasJoinedProject(state, action) {
      state.hasProjectJoined = action.payload;
    },
    getHasJoinedProjectError(state, action) {
      state.error = action.payload;
    },
    bootstrapAppError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getUserProjects = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/me/projects/detailed`);

    dispatch(slice.actions.getUserProjects(res.data));
  } catch (error) {
    const axiosError = new AxiosError(error);

    if (axiosError.response.status === 401) {
      window.location = '/login';
    }
    dispatch(slice.actions.getUserProjectsError(axiosError));
  }
};

export const getJoinProject = (projectId) => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/me/projects/${projectId}/join`);

    dispatch(slice.actions.getJoinProject(res.data));
  } catch (error) {
    const axiosError = new AxiosError(error);

    if (axiosError.response.status === 401) {
      window.location = '/login';
    }
    dispatch(slice.actions.getJoinProjectError(axiosError));
  }
};

export const bootstrapApp = (projectId) => async (dispatch) => {
  try {
    await getJoinProject(projectId)(dispatch);

    await getUserProjects()(dispatch);
    await getConsent(mockedProject.id)(dispatch);
    // eslint-disable-next-line no-empty
  } catch (error) {
    const axiosError = new AxiosError(error);

    if (axiosError.response.status === 401) {
      window.location = '/login';
    }
    dispatch(slice.actions.bootstrapAppError(axiosError));
  }
};

export const getHasJoinedProject = (projectId) => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/me/projects/${projectId}/joined`);

    dispatch(slice.actions.getHasJoinedProject(res.data));
    // eslint-disable-next-line no-empty
  } catch (error) {
    const axiosError = new AxiosError(error);

    if (axiosError.response.status === 401) {
      window.location = '/login';
    }
    dispatch(slice.actions.getHasJoinedProjectError(axiosError));
  }
};

export default slice;
